import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class UserAuthGuard implements CanActivate {

    constructor( private router: Router ) { }

    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
        if ( sessionStorage.getItem( 'currentUserSession' ) == 'true' ) {
            return true;
        } else {
            sessionStorage.setItem('palaniTempleUserDetail', null);
            location.href = 'home';
            return false;
        }
      }
}
