import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
    selector: 'app-menubar',
    templateUrl: './menubar.component.html',
    styleUrls: ['./menubar.component.css']
})

export class MenubarComponent implements OnInit {

    constructor() { }

    ngOnInit() {
        $('.navbar-collapse .ss-menu').click(function () {
            $(".navbar-collapse").collapse('hide');
        });
    }

    onActive() {
        window.scroll(0, 0);
    }
}
