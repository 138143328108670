export class StaticVariable {

    // static porturl = 'http://palanimurugantemple.org:8080/PalaniWebApi/';

    static porturl = 'http://palanimurugantemple.org:8080/TestPalaniWebApi/';

    // static porturl = 'http://localhost:8080/PalaniWebApi/';

    static status_1 = 'INITIAL';
    static status_2 = 'SUCCESS';
    static status_3 = 'FAILED';
    static status_4 = 'PENDING';

    static smsEncryptOffset = 25;

    static mail1 = '';
    static mail2 = 'palanimurugan@tnhrce.com';

    static tollFree = '18004259925';
    static landLine = '04545-241293 , 04545-240293';
    static technicalSupport = '9543361507, 8754097359, 7904067236';

    static pgKey1 = 'SxwgqL';
    static pgKey2 = '';
    static pgSalt1 = 'zC7VUYYY';
    static pgSalt2 = '';

}
