import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

    title = require('./../../../assets/images/header/header-title.png');
    lord = require( './../../../assets/images/header/header-image.png');

    constructor() { }

    ngOnInit() { }

}
