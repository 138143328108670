import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { LatestnewsPojo } from "app/management/latestnews/latestnews.pojo";
import { StaticVariable } from "app/globle.class";

@Injectable()

export class HomeService {

    constructor(private httpclient: HttpClient) { }

    getLatestnews(): Observable<LatestnewsPojo[]> {
        const url = StaticVariable.porturl + 'LatestNewsTrueAll';
        return this.httpclient.get<LatestnewsPojo[]>(url);
    }

}
