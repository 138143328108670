import { Component, OnInit } from '@angular/core';
import { HomeService } from "app/home/home.service";
import { LatestnewsPojo } from "app/management/latestnews/latestnews.pojo";
declare var $: any;
declare var require: any;
declare var bootbox: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    providers: [HomeService]
})
export class HomeComponent implements OnInit {

    latestnews: LatestnewsPojo[] = [];

    constructor(private homeservice: HomeService) { }

    m1 = require('../../assets/images/home/M1-1.jpg');
    m2 = require('../../assets/images/home/banner16-1.jpg');
    m3 = require('../../assets/images/home/palani Temple-1.jpg');
    m4 = require('../../assets/images/home/M4-1.jpg');
    murugan = require('../../assets/images/home/murugan1_4.gif');

    cradle = require('../../assets/images/home/cradle.jpg')
    hundi = require('../../assets/images/home/icon_money.png');
    accomodation = require('../../assets/images/home/accomodation.jpg');
    winch = require('../../assets/images/home/palani_winch.jpg');
    rope = require('../../assets/images/home/rope_car.jpg');
    donation = require('../../assets/images/home/donate_icon.png');
    book = require('../../assets/images/home/book_icon.png');
    ngOnInit() {

        this.homeservice.getLatestnews().subscribe(log => {
            this.latestnews = log;
        }, error => {
            bootbox.alert('Error');
        });

        $('.trigger').click(function (e) {
            // alert('tri');
            e.stopPropagation();
            if ($(this).hasClass('active')) {
                $('.trigger').removeClass('active');
                $('#e-hundi').css('display', 'none');

            } else {
                // alert('tri2');
                $('.trig').removeClass('active');
                $('#e-donation').css('display', 'none');
                $('.trigger').addClass('active');
                $('#e-hundi').css('display', 'block');
                $('.tri').removeClass('active');
                $('#e-booking').css('display', 'none');

            }
        });
        $('.trig').click(function (e) {
            e.stopPropagation();
            if ($(this).hasClass('active')) {
                //    alert('tri3');
                $('.trig').removeClass('active');
                $('#e-donation').css('display', 'none');

            } else {
                // alert('tri3');
                $('.trigger').removeClass('active');
                $('#e-hundi').css('display', 'none');
                $('.trig').addClass('active');
                $('#e-donation').css('display', 'block');
                $('.tri').removeClass('active');
                $('#e-booking').css('display', 'none');

            }
        });
        $('.tri').click(function (e) {
            e.stopPropagation();
            if ($(this).hasClass('active')) {
                $('.tri').removeClass('active');
                $('#e-booking').css('display', 'none');

            } else {
                $('.tri').addClass('active');
                $('#e-booking').css('display', 'block');
                $('.trig').removeClass('active');
                $('#e-donation').css('display', 'none');
                $('.trigger').removeClass('active');
                $('#e-hundi').css('display', 'none');


            }
        });
        $('body').click(function () {

            // alert('click');
            if ($('.trigger').hasClass('active')) {
                //  alert('open');
                $('.trigger').removeClass('active');
                $('#e-hundi').css('display', 'none');
            }
            if ($('.trig').hasClass('active')) {
                //  alert('open');
                $('.trig').removeClass('active');
                $('#e-donation').css('display', 'none');
            }
            if ($('.tri').hasClass('active')) {
                //  alert('open');
                $('.tri').removeClass('active');
                $('#e-booking').css('display', 'none');
            }

        });
        /* $('.trigger active').click(function(){
             alert('click1');
             $('.trigger').removeClass('active');
             $('.panel').css('display','none');
         });*/
        $(function ($) {

            var demo = {
                init: function () {
                    this.carouselControls();

                    $(window).resize(function () { demo.carouselControls(); });
                },
                carouselControls: function () {
                    var $container = $('#main');
                    var $slider = $('#carousel-example-generic');
                    var padding = 15;

                    var rt = ($(window).width() - ($container.offset().left + $container.outerWidth()));
                    $slider.find('.right.carousel-control').css('right', rt + padding).show();
                    $slider.find('.left.carousel-control').css('left', rt + padding).show();
                }
            };

            demo.init();

        });
    }
}

