import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserAuthGuard } from './user-authentication/user-auth.guard';

import { MenubarComponent } from './menubar/menubar.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
    {
        path: '', component: MenubarComponent,
        children: [
            { path: '', component: HomeComponent },
            { path: 'home', component: HomeComponent },
            { path: 'history', loadChildren: './history/history.module#HistoryModule' },
            { path: 'festivals', loadChildren: './festivals/festivals.module#FestivalsModule' },
            { path: 'worship', loadChildren: './worship/worship.module#WorshipModule' },
            { path: 'facilities', loadChildren: './facilities/facilities.module#FacilitiesModule' },
            { path: 'gallery', loadChildren: './gallery/gallery.module#GalleryModule' },
            { path: 'services', loadChildren: './services/services.module#ServicesModule' },
            { path: 'location', loadChildren: './location/location.module#LocationModule' },
            { path: 'livestream', loadChildren: './live-stream/live-stream.module#LiveStreamModule' },
            { path: 'login', loadChildren: './login/login.module#LoginModule' },
            { path: 'receipt', loadChildren: './receipt/receipt.module#ReceiptModule' }
        ]
    },
    { path: 'user', loadChildren: './user/user.module#UserModule', canActivate: [UserAuthGuard] },
    { path: 'management', loadChildren: './management/management.module#ManagementModule' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})

export class AppRoutingModule { }
